<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="mega-menu">
    <transition :css="false" mode="out-in" @enter="onEnter" @leave="onLeave">
      <div v-show="isActive" ref="navRef" class="mega-menu-nav">
        <div class="mega-menu-top">
          <button
            v-if="uiStore.catMobExpanded && subCatTitle"
            class="mega-menu-back"
            @click="uiStore.catMobExpanded = false"
          >
            <SvgSprite
              class="icon"
              symbol="ui-arrow-long-left"
              size="48"
              aria-hidden="true"
            />

            <span class="h-visually-hidden">BACK</span>
            <span>{{ subCatTitle }}</span>
          </button>
        </div>

        <div class="mega-menu-wrapper">
          <nav class="mega-menu-inner">
            <ul class="mega-menu__list">
              <li
                v-for="item in chrome.menus.main"
                :key="item.url"
                class="mega-menu__item"
                data-animate
              >
                <ul v-if="item.children" class="mega-menu__list">
                  <li
                    v-for="subcategory in item.children"
                    :key="subcategory.url"
                    class="mega-menu__item"
                    data-animate
                  >
                    <button
                      class="mega-menu__item--primary"
                      @click="toggleSub(subcategory as SubCategory)"
                    >
                      <span>{{ subcategory.title }}</span>

                      <SvgSprite
                        class="icon"
                        symbol="ui-arrow-right"
                        size="12"
                        aria-hidden="true"
                      />
                    </button>
                  </li>
                </ul>

                <RouterLink
                  v-else
                  class="mega-menu__item--primary"
                  :to="item.url"
                  >{{ item.title }}</RouterLink
                >
              </li>
            </ul>

            <ul class="mega-menu__list">
              <li
                v-for="item in chrome.menus.top"
                :key="item.url"
                class="mega-menu__item"
                data-animate
              >
                <RouterLink :to="item.url" class="mega-menu__item__link">
                  <span v-html="item.title"></span>
                </RouterLink>
              </li>
            </ul>
          </nav>

          <div class="mega-menu-contact wrapper">
            <div class="mega-menu-contact__item" data-animate>
              <SvgSprite
                class="icon"
                symbol="ui-pin"
                size="20"
                aria-hidden="true"
              />
              <span>
                <a :href="chrome.contact.addressUrl" target="_blank">
                  {{ chrome.contact.address }}
                </a>
              </span>
            </div>
            <div class="mega-menu-contact__item" data-animate>
              <SvgSprite
                class="icon"
                symbol="ui-phone"
                size="20"
                aria-hidden="true"
              /><span>
                <a :href="chrome.contact.phone.url">{{
                  chrome.contact.phone.title
                }}</a></span
              >
            </div>
          </div>

          <div class="mega-menu-other wrapper" data-animate>
            <ChromeLangMobile class="mega-menu__lang" />

            <ul class="mega-menu-some">
              <li
                v-for="(item, index) in chrome.socialNetworks"
                :key="`some-${index}`"
                class="mega-menu-some__item"
              >
                <a
                  v-if="item.url"
                  :href="item.url"
                  target="_blank"
                  :aria-label="item.title"
                  class="mega-menu-some__item__link"
                  @click="onClick(item.url)"
                >
                  <SvgSprite
                    class="icon"
                    :symbol="`social-${item.title}`"
                    size="36"
                    aria-hidden="true"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="mega-menu-action wrapper" data-animate-cta>
          <Action
            v-if="chrome.cta"
            :content="{
              ...chrome.cta,
              icon: 'calendar',
              modifiers: ['reversed'],
            }"
          />
        </div>
      </div>
    </transition>

    <transition name="slide-fade">
      <div v-show="uiStore.catMobExpanded" class="mega-menu-nav--subnav">
        <ChromeSubNav :content="subCatData"></ChromeSubNav>
      </div>
    </transition>

    <transition name="custom-fade">
      <div v-show="isActive" class="mega-menu-overlayer"></div>
    </transition>

    <button class="mega-menu__trigger" @click="toggle">
      <SvgSprite
        v-if="isActive"
        class="icon"
        symbol="ui-close"
        size="40"
        aria-hidden="true"
      />
      <SvgSprite
        v-else
        class="icon"
        symbol="ui-menu"
        size="40"
        aria-hidden="true"
      />
    </button>
  </div>
</template>

<script lang="ts">
interface SubCategory {
  title: string
  url?: string
  children: []
}
</script>

<script setup lang="ts">
import gsap from 'gsap'
import { CustomEase } from 'gsap/CustomEase'
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'

import ChromeLangMobile from '@/components/chrome/LangMobile.vue'
import ChromeSubNav from '@/components/chrome/SubNav.vue'
import { hasMotion } from '@/core/prefers'
import { useChromeStore } from '@/stores/chrome'
import { useUiStore } from '@/stores/ui'
import { push } from '@/utils/tracking'

interface Ease {
  enter: gsap.EaseFunction
  leave: gsap.EaseFunction
}

interface Transition {
  name: string
  eases: Record<string, Ease>
  childrenMotionDistance: number
  tlIn?: gsap.core.Timeline
  tlOut?: gsap.core.Timeline
  setup: () => void
  initTlIn: (el: Element) => void
  initTlOut: (el: Element) => void
}

const route = useRoute()
const chrome = useChromeStore()
const uiStore = useUiStore()

const navRef = ref<HTMLElement>()
const isActive = ref(false)
const subCatData = ref({} as SubCategory[])
const subCatTitle = ref('')

const toggle = () => {
  const allDetails = document.querySelectorAll('.details')
  isActive.value = !isActive.value

  if (uiStore.catMobExpanded) {
    uiStore.catMobExpanded = false
  }

  if (isActive.value) {
    uiStore.toggleScroll(false)
    uiStore.hasTransition = 'menu'
  } else {
    uiStore.toggleScroll(true)
    uiStore.hasTransition = 'none'

    // Close all details
    allDetails.forEach((item, i) => {
      item.removeAttribute('open')
    })
  }
}

const toggleSub = (menu: SubCategory) => {
  uiStore.catMobExpanded = !uiStore.catMobExpanded
  subCatData.value = menu.children
  subCatTitle.value = menu.title
}

const transition: Transition = {
  name: 'megamenu-transition',
  eases: {},
  childrenMotionDistance: 50,
  setup() {
    gsap.registerPlugin(CustomEase)

    this.eases = {
      el: {
        enter: CustomEase.create('custom', 'M0,0,C1,0,0,1,1,1'),
        leave: CustomEase.create('custom', 'M0,0,C0,1,0.58,1,1,1'),
      },
      children: {
        enter: CustomEase.create('custom', 'M0,0,C0.55,0,0,1,1,1'),
        leave: CustomEase.create('custom', 'M0,0,C0.67,0,0.28,1,1,1'),
      },
    }
  },

  initTlIn(el: Element) {
    const children = el.querySelectorAll('[data-animate]')
    const cta = el.querySelector('[data-animate-cta]')

    this.tlIn = gsap.timeline()

    if (el) {
      this.tlIn.fromTo(
        el,
        {
          clipPath: 'inset(0% 0% 0% 100%)',
        },
        {
          clipPath: 'inset(0% 0% 0% 0%)',
          ease: this.eases.el.enter,
          duration: 0.4,
        },
        'start'
      )
    }

    if (children.length > 0) {
      this.tlIn.fromTo(
        children,
        {
          opacity: 0,
          y: this.childrenMotionDistance,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.05,
          duration: 1.2,
          ease: this.eases.children.enter,
        },
        '-=0.2'
      )
    }

    if (cta) {
      this.tlIn.fromTo(
        cta,
        {
          opacity: 0,
          y: this.childrenMotionDistance,
        },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: this.eases.children.enter,
        },
        '-=.8'
      )
    }
  },
  initTlOut(el: Element) {
    const children = el.querySelectorAll('[data-animate]')
    const cta = el.querySelector('[data-animate-cta]')

    this.tlOut = gsap.timeline()

    if (children.length > 0) {
      this.tlOut.to(children, {
        opacity: 0,
        duration: 0.4,
        y: -this.childrenMotionDistance,
        ease: this.eases.children.leave,
      })
    }

    if (cta) {
      this.tlOut.to(
        cta,
        {
          opacity: 0,
          duration: 0.4,
          y: this.childrenMotionDistance,
          ease: this.eases.children.leave,
        },
        '-=.2'
      )
    }

    if (el) {
      this.tlOut.to(el, {
        clipPath: 'inset(0% 0% 0% 100%)',
        duration: 0.2,
        ease: this.eases.el.leave,
      })
    }
  },
}

if (!import.meta.env.SSR) {
  transition.setup()
}

const onEnter = (el: Element, done: () => void) => {
  if (navRef.value) {
    navRef.value.scrollTo(0, 0)
  }

  if (hasMotion.value) {
    transition.tlOut?.isActive() && transition.tlOut.kill()
    !transition.tlIn && transition.initTlIn(el)
    transition.tlIn!.invalidate().restart().then(done)
  } else {
    done()
  }
}

const onLeave = (el: Element, done: () => void) => {
  if (hasMotion.value) {
    transition.tlIn?.isActive() && transition.tlIn.kill()
    !transition.tlOut && transition.initTlOut(el)
    transition.tlOut!.invalidate().restart().then(done)
  } else {
    done()
  }
}

const onClick = (url: string) => {
  push({
    event: 'social_link_click',
    ctaLocation: 'header',
    linkUrl: url,
  })
}

watch(route, () => {
  if (isActive.value) {
    toggle()
  }
})
</script>

<style lang="scss" scoped>
$action-cta-height: 9.3rem;

.mega-menu,
[class*='mega-menu--'] {
  margin-left: 11rem;
}

.mega-menu-nav,
[class*='mega-menu-nav--'] {
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  overflow: auto;
  width: 100%;
  max-width: 39rem;
  height: calc(100 * var(--vh));
  place-items: center;
  background-color: $c-white;

  .no-scroll & {
    padding-right: var(--scrollbar-width);
  }

  &[class*='--subnav'] {
    top: 6rem;
    height: calc(100dvh - 6rem - $action-cta-height);
  }
}

.mega-menu__list {
  margin-bottom: 5.2rem;
  padding: 0;
  list-style-type: none;

  .mega-menu__list {
    margin-bottom: 0;
  }
}

.mega-menu__item,
[class*='mega-menu__item--'] {
  @extend %button-nostyle;
  @extend %fw-normal;

  margin-bottom: 0.8rem;
  font-size: 1.6rem;
  line-height: 1.75;

  &[class*='--primary'] {
    @extend %fraunces-spec;
    @extend %fw-light;

    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 0.4rem;
    color: $c-black;
    font-size: 3.6rem;
    line-height: 1.15;

    svg {
      margin-left: auto;
    }
  }
}

.mega-menu__item__link {
  color: var(--c-text);
  text-decoration: none;
}

[data-animate] {
  will-change: transform;
}

.mega-menu__trigger {
  position: relative;
  z-index: 1;
  color: currentcolor;
  background: transparent;
  border-width: 0;
  cursor: pointer;
}

.mega-menu__trigger__svg {
  display: block;
  fill: currentcolor;
}

.mega-menu-overlayer {
  @include get-all-space;

  position: fixed;
  z-index: 0;
  height: 100vh;
  background-color: rgba($c-black, 80%);
}

.mega-menu-top {
  display: flex;
  align-items: center;
  width: 100%;
  height: 6rem;
  padding: 0 $spacing;
  border-bottom: 1px solid $c-grey-light;
}

.mega-menu-wrapper {
  overflow: auto;
  max-height: calc(100dvh - 6rem);
  padding-bottom: 15rem;
}

.mega-menu-inner {
  margin-top: 2.6rem;
  padding: 0 $spacing;
}

.mega-menu-contact {
  padding-top: 2.4rem;
  border-top: 1px solid $c-grey-light;
}

.mega-menu-contact__item {
  display: flex;
  align-items: baseline;
  margin-right: $spacing;
  margin-bottom: 1.2rem;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.8rem;

  .icon {
    margin-right: 1.2rem;
    fill: $c-grey-medium;
    transform: translateY(0.4rem);
  }

  a {
    color: $c-black;
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 0.6;
    }
  }
}

.mega-menu-other {
  display: flex;
  justify-content: space-between;
  padding-block: 2.4rem 3.6rem;
}

.mega-menu-action {
  padding-block: 2.4rem;
  position: fixed;
  top: calc(100dvh - $action-cta-height);
  right: 0;
  max-width: 39rem;
  height: $action-cta-height;
  background-color: $c-white;
  border-top: 1px solid $c-gray-lighter;
}

.mega-menu-some {
  @extend %list-nostyle;

  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.mega-menu-some__item__link {
  opacity: 1;
  transition: 0.2s $ease-in;
  will-change: opacity;

  &:hover {
    opacity: 0.5;
  }
}

.mega-menu-back {
  @extend %button-nostyle;

  display: flex;
  align-items: center;

  span {
    margin-left: 1.6rem;
    color: $c-green-abr;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 2.4rem; /* 150% */
  }
}

.mega-menu__item--primary {
  @extend %focusable;
}
</style>
